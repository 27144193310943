@import "../../../styles/media-queries.scss";

// border animation for newly added/edited cards 

/* The animation starts here */

.animated-box:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //border-radius: 4px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%, rgba(236, 208, 140, 1) 49%, rgba(236, 208, 140, 1) 52%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100%); // background: linear-gradient(to right, #ffffff00 20%, #ffffff7f 40%, #ECD08C 50%, #ECD08C 55%, #ffffff83 60%, #ffffff00 100%);
  background-size: 300% 300%;
  clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
}

.animated-box.in:after {
  animation: frame-enter 1s forwards ease-in-out reverse, gradient-animation 4s ease-in-out infinite;
}

/* motion */
@keyframes gradient-animation {
  0% {
    background-position: 15% 0%;
  }

  50% {
    background-position: 85% 100%;
  }

  100% {
    background-position: 15% 0%;
  }
}


@keyframes frame-enter {
  0% {
    clip-path: polygon(0% 100%, 8px 100%, 8px 8px, calc(100% - 8px) 8px, calc(100% - 8px) calc(100% - 8px), 3px calc(100% - 8px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
  }

  25% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 100%, 100% 100%, 100% 0%, 0% 0%);
  }

  50% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, 100% 0%, 0% 0%);
  }

  75% {
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 0%, 0% 0%);
  }

  100% {
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 0% 100%);
  }
}


.btn-primary {
  color: #fff;
  background-color: #6264a7;

  &:hover {
    background-color: rgb(88, 90, 150);
  }
}

.cst-shadow {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.signup-banner-config {
  position: absolute;
  transform: translate(-50%, 0%);
  left: 50%;
  top: 8px;
  z-index: 100;
}

.dashboardheader {
  @include lt-sm {
    >.ui-dropdown {
      width: 100%;

      >.ui-dropdown__container {
        width: 100%;
      }
    }

    >.ui-flex {
      width: 100%;
      justify-content: flex-end;
      -webkit-justify-content: flex-end;
      // white-space: nowrap;
    }
  }
}

.react-grid-item {
  overflow: hidden;

  >.ui-card {
    width: 100% !important;
  }

  button {
    z-index: 10;
  }
}

.dashCardForm {
  input {
    width: 100%;
  }
}

.editDashboardDialog {
  max-width: 400px;
  overflow-wrap: anywhere;

  >.ui-header {
    margin-bottom: 20px;
  }

  >div.ui-box {
    margin-bottom: 0;
  }
}

.dashboardsContainer {
  grid-template-columns: repeat(1, 100%);

  .ui-card.dashboardCard {
    .dashboardName {
      width: calc(100% - 260px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    @include lt-sm {
      padding: 7px 0;

      .dashboardName {
        width: calc(100% - 150px);
      }

      .k-dialog-wrapper button,
      .memberButton {
        min-width: 5rem;

        >.ui-box {
          margin-right: 0.6944rem;
        }

        >span.ui-button__content {
          display: block;
        }
      }

      button {
        min-width: auto;

        >.ui-box {
          margin-right: 0;
        }

        >span.ui-button__content {
          display: none;
        }
      }
    }

    width: 100%;
    box-shadow: none !important;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-radius: 0;
    padding: 7px 12px;

    &:hover:first-child {
      background-color: transparent !important;
    }

    .dashCardForm {
      flex-direction: row;
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
      align-items: center;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
    }

    >.ui-grid:first-child {
      padding-right: 12px;

      @include lt-sm {
        &.groups {
          grid-template-columns:
            minmax(150px, 1fr) minmax(100px, 1fr) minmax(70px, 1fr) minmax(80px, 1fr);
        }

        &.people {
          grid-template-columns:
            minmax(50px, 1fr) minmax(150px, 1fr) minmax(70px, 1fr) minmax(80px, 1fr) minmax(90px, 1fr) minmax(120px, 1fr) minmax(60px, 1fr);
        }

        &.cards {
          grid-template-columns: 23px minmax(100px, 1fr) 80px 40px 150px 75px;
          /*grid-template-columns: 23px repeat(auto-fill, minmax(54px, 1fr));*/
        }

        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        overflow-wrap: anywhere;
        padding-right: 0;

        button {
          min-width: auto;
        }
      }
    }
  }
}

body.default {
  .react-grid-placeholder {
    background-color: #6264a7 !important;
  }

  .dashboardsContainer,
  .addcardcontainer {

    .ui-card.dashboardCard,
    .categorylist {
      border-bottom-color: rgb(237, 235, 233);
    }

    .ui-card.dashboardCard.focused {
      background-color: #464775 !important;
      color: #fff;

      .btnFormAction svg {
        fill: #fff;
      }
    }

    .ui-card.dashboardCard:hover {
      .widget_visualization_container {
        color: #fff;
      }
    }
  }

  .widget_visualization_container {
    color: rgb(70, 71, 117);
  }

  .widget_target_container,
  .widget_category_container {
    >i {
      background: rgb(98, 100, 167);
      color: #fff;
    }
  }

  .dashboard-selectbox {
    >.ui-dropdown__container {
      box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
      border-color: rgb(225, 223, 221);
    }
  }
}

body.dark {
  .react-grid-placeholder {
    background-color: #c8c6c4 !important;
  }

  .dashboardsContainer,
  .addcardcontainer {

    .ui-card.dashboardCard,
    .categorylist {
      border-bottom-color: rgb(59, 58, 57);
    }

    >div:first-child {
      background-color: transparent !important;
    }
  }

  .dashboardsContainer,
  .addcardcontainer {
    >div:hover:first-child {
      color: #fff;
    }
  }

  .widget_visualization_container {
    color: #fff;
  }

  .widget_target_container,
  .widget_category_container {
    >i {
      background: rgb(98, 100, 167);
      color: rgb(32, 31, 31);
    }
  }

  .dashboard-selectbox {
    >.ui-dropdown__container {
      border-color: rgb(96, 94, 92);
    }
  }
}

body.contrast {
  .react-grid-placeholder {
    background-color: #dafcff !important;
  }

  .dashboardsContainer,
  .addcardcontainer {
    >div:hover:first-child {
      color: #fff;
    }
  }

  // .dashboardsContainer {
  //     > div.ui-card:not(:first-child):hover{
  //         .widget_visualization_container{
  //             color:rgb(0, 0, 0);
  //         }
  //     }
  // }
  .widget_visualization_container {
    color: rgb(255, 255, 1);
    // color: #fff;
  }

  .widget_target_container,
  .widget_category_container {
    >i {
      background: rgb(255, 255, 1);
      // background: #fff;
      color: rgb(0, 0, 0);
    }
  }
}

.addcardcontainer,
.editcardcontainer {
  @include lt-sm {
    flex-direction: column;

    >div:first-child {
      margin-bottom: 0.625rem;
      margin-right: 0;
    }

    >div:last-child>div {
      padding: 0;
    }

    .categorylist {
      // display: inline-flex;
    }

    .dashboardsContainer {
      overflow-x: auto;
      grid-template-columns: repeat(1, 100%);
    }
  }

  &:hover:first-child {
    background-color: transparent !important;
  }

  .widget_visualization_container {
    font-size: 24px;
    height: 30px;
    width: 24px;
  }

  .widget_target_container,
  .widget_category_container {
    font-size: 12px;

    >i {
      border-radius: 50%;
      padding: 4px;
    }
  }
}

.ui-dialog.shareDialog {
  >div {
    text-align: center;
  }

  .ui-button {
    margin-top: 20px;
  }
}

.visualisation-span {
  width: 20px;

  i {
    transform: rotate(-90deg);
    font-size: 20px;
    display: block;
    height: 20px;
    width: 20px;
  }
}

// @media (max-width: 767px){
//     .addcardcontainer{
//         flex-direction: column;
//         > div:first-child{
//             margin-bottom: 0.625rem;
//         }
//         .categorylist{
//             display: inline-flex;
//         }
//     }
// }
.react-grid-item>.ui-card {
  >.ui-card__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    height: 44px;
  }

  >.ui-card__body {
    height: calc(100% - 45px);
  }
}

.dashboard-selectbox {
  >.ui-dropdown__container {
    border-width: 1px;
    border-style: solid;
  }
}

.demoModeArea {
  display: flex;
  align-items: center;
}

.demoMode {
  display: flex;
  align-items: center;
  text-align: right;
}

//change color for pinned card

.bg-flash-demo {
  animation: bgFlashDemo 7s infinite;
}

@keyframes bgFlashDemo {
  0% {
    background-color: #fff3cd;
  }

  50% {
    background-color: #ffc107;
  }

  100% {
    background-color: #fff3cd;
  }
}

.xzy {
  // dl {
  //   border-radius: .5rem;
  //   background-color: rgba(255, 255, 255, 0.5);
  // }
}

.zyx {
    background-image: url('../../../../public/images/a365-logo-bar.svg');
    background-position: 2vw bottom;
    background-repeat: no-repeat;
    background-size: 40rem;


    .k-dialog {
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px !important;
        backdrop-filter: blur(16px) !important;
    }

    .k-overlay {
        opacity: .5 !important;
    }

    .area {
        background: transparent;
        width: 100%;
        height: 100vh;
        position: absolute;
    }

    .circles {
        position: absolute;
        transform: translate(2%, -40%);
        margin: 0;
        top: 0;
        left: 0;
        padding-left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .circles li {
        position: absolute;
        display: block;
        list-style: none;
        width: 20px;
        height: 20px;
        background: rgba(78, 84, 200, 1);
        animation: animate 25s linear infinite;
        bottom: -150px;
    }

    .circles li:nth-child(1) {
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
    }


    .circles li:nth-child(2) {
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s;
    }

    .circles li:nth-child(3) {
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s;
    }

    .circles li:nth-child(4) {
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s;
    }

    .circles li:nth-child(5) {
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s;
    }

    .circles li:nth-child(6) {
        left: 75%;
        width: 110px;
        height: 110px;
        animation-delay: 3s;
    }

    .circles li:nth-child(7) {
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
    }

    .circles li:nth-child(8) {
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s;
    }

    .circles li:nth-child(9) {
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s;
    }

    .circles li:nth-child(10) {
        left: 85%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
    }



    @keyframes animate {

        0% {
            transform: translateY(0) rotate(0deg);
            opacity: 1;
            border-radius: 0;
        }

        100% {
            transform: translateY(-1000px) rotate(720deg);
            opacity: 0;
            border-radius: 50%;
        }
    }
}

.vl2p-primary {
  border-left: solid 2px #6264a7 !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

//dashboards.js_926
.flexDirCol {
  display: flex;
  flex-direction: column;
}

//dashboard.js_583
.w176fixSm {
  .ui-dropdown__container {
    width: 176px !important;
  }
}

//editcard.js_45
.myCardsSm {
  overflow: auto !important;
  max-height: 24vh !important;
  font-size: .9em !important;

  li {
    min-height: 32px !important;
  }
}